




















































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { Watch } from 'vue-property-decorator'
import { Debounce } from 'lodash-decorators'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component
export default class Edit extends Vue {
  private loading = true
  private saveIsLoading = false
  private valid = true
  private firstTimeLoading = true
  private useRewrittenTyreHotelView = false

  private data = {
    season: null,
    tyreHotelId: null,
    carId: null,
    updateTyreHotelTreadDepths: null,
    updateTyreHotelTyreData: null,
    updateLastTyreHotelCertificate: null,
    updateOption: null,
    licensePlate: null,
  }

  private updateOptions = [
    {
      value: 'READING_SEASON',
      text: this.$t('c:tread-depth-reader:Based on the reading season'),
    },
    {
      value: 'WHEEL_SET_ON_CAR',
      text: this.$t('c:tread-depth-reader:Wheel set that is on car'),
    },
    {
      value: 'WHEEL_SET_IN_HOTEL',
      text: this.$t('c:tread-depth-reader:Wheel set that is in hotel'),
    },
  ]

  private seasons = []

  private tyreHotels = []

  private cars = []
  private carSearchLoading = false
  private carSearch = ''

  private rules = {
    season: [],
    car: [],
    tyreHotel: [],
  }

  private appendSitePrefix = appendSitePrefix

  private created() {
    this.loading = true

    this.$axios
      .get('/v4/site/tread-depth-readings/edit/initial-data')
      .then((response) => {
        this.seasons = response.data.seasons
        this.useRewrittenTyreHotelView = response.data.useRewrittenTyreHotelView

        this.$axios
          .get('/v4/site/tread-depth-readings/' + this.$route.params.id)
          .then((response) => {
            const reading = response.data.data

            if (reading.car) {
              const selectedCar = { id: reading.car.id, licenseplate: reading.car.licenseplate }
              this.cars = [selectedCar]
            }

            this.data = reading
          })
          .catch((err) => {
            err.response.data.errors.forEach((v) => {
              vxm.alert.error({
                content: v.message as string,
                title: this.$t('c:common:Error') as string,
              })
            })
          })
          .finally(() => {
            this.loading = false
          })
      })
      .catch((err) => {
        err.response.data.errors.forEach((v) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
  }

  private getBack() {
    this.$router.back()
  }

  private save() {
    this.saveIsLoading = true

    this.$axios
      .put('/v4/site/tread-depth-readings/' + this.$route.params.id, this.data)
      .then((response) => {
        this.tyreHotels = response.data.data
        this.$router.push({ name: 'Car/TreadDepthReading/Show', params: { id: this.$route.params.id } })
      })
      .catch((err) => {
        err.response.data.errors.forEach((v) => {
          vxm.alert.error({
            content: v.message as string,
            title: this.$t('c:common:Error') as string,
          })
        })
      })
      .finally(() => {
        this.saveIsLoading = false
      })
  }

  private get updateTyreHotelTreadDepthsLabel() {
    return this.$t('c:tread-depth:On save update tyre tread depths of tyre hotel') + ' ' + this.data.tyreHotelId
  }

  private get updateTyreHotelTyreDataLabel() {
    return this.$t('c:tread-depth:On save update tyre data of tyre hotel') + ' ' + this.data.tyreHotelId
  }

  private get updateLastTyreHotelCertificateLabel() {
    return (
      this.$t('c:tread-depth:Update last tyre hotel certificate with these changes for tyre hotel') +
      ' ' +
      this.data.tyreHotelId
    )
  }

  @Watch('data.carId')
  private onCarChanged() {
    if (this.data.carId !== null) {
      let url = ''
      if (this.useRewrittenTyreHotelView) {
        url = '/v4/site/tyre-hotels-v2/get-by-car/' + this.data.carId
      } else {
        url = '/v4/site/tyre-hotels/get-by-car/' + this.data.carId
      }

      this.$axios
        .get(url)
        .then((response) => {
          this.tyreHotels = response.data.data
        })
        .catch((err) => {
          err.response.data.errors.forEach((v) => {
            vxm.alert.error({
              content: v.message as string,
              title: this.$t('c:common:Error') as string,
            })
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }

  @Watch('carSearch')
  @Debounce(300)
  private onCarSearchChanged(val) {
    if (this.firstTimeLoading || val === null || val.length < 2) {
      this.firstTimeLoading = false
      return
    }

    this.carSearchLoading = true
    this.$axios
      .get('/v4/site/cars/search?licensePlate=' + val)
      .then((response) => {
        this.cars = response.data.data
      })
      .finally(() => {
        this.carSearchLoading = false
      })
  }
}
